import { apiProvider } from "./provider";

export class ApiCore {
  constructor(options) {
    if (options.list) {
      this.list = (params, url) => {
        return apiProvider.list(url || options.url, params);
      };
    }

    if (options.get) {
      this.get = (id, url) => {
        return apiProvider.get(url || options.url, id);
      };
    }

    if (options.post) {
      this.post = (model, url) => {
        return apiProvider.post(url || options.url, model);
      };
    }

    if (options.put) {
      this.put = (model, url) => {
        return apiProvider.put(url || options.url, model);
      };
    }

    if (options.patch) {
      this.patch = (model, url) => {
        return apiProvider.patch(url || options.url, model);
      };
    }

    if (options.delete) {
      this.delete = (id, url) => {
        return apiProvider.delete(url || options.url, id);
      };
    }
  }
}
