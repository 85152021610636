import React, { useEffect, useRef, useState } from 'react';
import { apiInspectors } from "../api/inspectors";
import { apiInspections } from "../api/inspections";
import { Loader } from 'google-maps';

const formatDate = date => {
  const d = new Date(date);
  return d.toLocaleDateString();
};

const Planner = () => {

  const [inspectors, setInspectors] = useState([]);
  const [selectedInspector, setSelectedInspector] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
  // const [selectedDate, setSelectedDate] = useState("2023-08-07");
  const [showInspectionsWithNoAppointment, setShowInspectionsWithNoAppointment] = useState(false);
  const [plannedInspections, setPlannedInspections] = useState([]);
  const [google, setGoogle] = useState(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [mapInfoWindow, setMapInfoWindow] = useState(null);

  const mainDiv = useRef();

  const options = {/* todo */};
  const loader = new Loader('AIzaSyBHDVtQHIMXzOr7xjqadRZtScytVh__xfA', options);

  const getColorForInspection = inspection => {
    if (inspection.inspection_time === "--") {
      return "blue";
    }
    return "red";
    //
    // switch (inspection.type) {
    // case "A":
    //   return "red";
    // case "B":
    // case "B+":
    //   return "blue";
    // case "B++":
    //   return "green";
    // case "BR":
    //   return "orange";
    // default:
    //   return "yellow";
    // }
  };

  const columns = [
    {
      Header: "Policy", accessor: "policy_no",
      Cell: props => <a target={"_blank"} href={"/inspections/" + props.row.original.policy_no}
        rel="noreferrer">{props.row.original.policy_no}</a>
    },
    { Header: "Insured", accessor: "insured" },
    { Header: "Address", accessor: "address" },
    { Header: "City", accessor: "city" },
    { Header: "State", accessor: "state" },
    { Header: "Zip", accessor: "zip" },
    {
      Header: "Type",
      Cell: props => <p className={"tw-text-white tw-p-1 tw-w-8 tw-rounded tw-text-center"}
        style={{ backgroundColor: getColorForInspection(props.row.original) }}>{props.row.original.type}</p>
    },
  ];

  const setMainDivSize = () => {
    if (!mainDiv.current) {
      return;
    }
    const height = window.innerHeight - mainDiv.current.offsetTop - 50;
    mainDiv.current.style.height = `${height}px`;
  };

  const setAppointment = async (inspectionId, time) => {
    const inspection = plannedInspections.find(i => i.id === inspectionId);
    const timeStr = time === 1 ? "AM" : time === 2 ? "Midday" : "PM";
    const newInspection = { ...inspection, inspection_time: timeStr };
    const updatedInspections = plannedInspections.map(i => i.id === inspectionId ? newInspection : i);
    setPlannedInspections(updatedInspections);
    // const updatedMarkers = markers.map(async marker => {
    //   if (marker.inspectionId === inspectionId) {
    //   // if (marker.position.lat === inspection.lat && marker.position.lng === inspection.lng) {
    //     marker.setMap(null);
    //     const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    //     return createMarker(AdvancedMarkerElement, newInspection);
    //   }
    //   return marker;
    // });
    // setMarkers(updatedMarkers);
    if (mapInfoWindow) {
      mapInfoWindow.close();
    }
  };

  const initMap = async () => {
    const google = await loader.load();
    setGoogle(google);
    const map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 25.782479, lng: -80.2706578 },
      zoom: 8,
      mapId: '8041c9b566fb264'
    });
    setMap(map);

    setMapInfoWindow(new google.maps.InfoWindow());

  };

  useEffect(() => {
    const initData = async () => {
      const inspectors = await apiInspectors.get();
      setInspectors(inspectors.data.result);
    };

    initData();
    initMap();
    window.onresize = () => {
      setMainDivSize();
    };
    setMainDivSize();
  }, []);

  useEffect(() => {
    const updateInspections = async () => {
      const inspections = await apiInspections.getPlannedInspections(selectedInspector, selectedDate, showInspectionsWithNoAppointment);
      setPlannedInspections(inspections.data.result);
    };
    if (selectedInspector && selectedDate) {
      updateInspections();
    }
  }, [selectedInspector, selectedDate, showInspectionsWithNoAppointment]);

  useEffect(() => {
    updateMapBounds();
    setMainDivSize();
  }, [map, plannedInspections]);

  function createMarker(AdvancedMarkerElement, inspection) {

    const markerTag = document.createElement("div");

    markerTag.className = "inspection-map-tag";
    switch (inspection.type) {
    case "A":
      markerTag.classList.add("inspection-map-tag-a");
      break;
    case "B":
    case "B+":
      markerTag.classList.add("inspection-map-tag-bplus");
      break;
    case "B++":
      markerTag.classList.add("inspection-map-tag-bplusplus");
      break;
    case "BR":
      markerTag.classList.add("inspection-map-tag-br");
      break;
    default:
      markerTag.classList.add("inspection-map-tag-other");
    }

    if (inspection.inspection_time === "--") {
      markerTag.classList.add("inspection-map-tag-no-time");
    }

    markerTag.textContent = inspection.type;
    markerTag.id = `ins-${inspection.id}`;
    markerTag.style.backgroundColor = getColorForInspection(inspection);
    // font-size: 12px;
    //     padding: 8px 8px;
    markerTag.style.padding = "8px 8px";
    markerTag.style.fontSize = "0.8rem";

    const marker = new AdvancedMarkerElement({
      map,
      position: { lat: inspection.lat, lng: inspection.lng },
      content: markerTag,
    });

    marker.addListener("click", () => {

      let content = "";
      content = content + `<div>`;
      content = content + `<p class="tw-text-lg">${inspection.insured}</p>`;
      content = content + `<p>${inspection.address}<br/>${inspection.city}, ${inspection.state} ${inspection.zip}</p>`;

      // can't believe this hack actually works - call react function from plain js
      window.setAppointmentFunc = setAppointment;

      if (inspection.inspection_time === "--") {
        content = content + `<div class="tw-flex tw-gap-4 tw-mt-2">`;
        content = content + `<button class="tw-mt-4 btn-primary btn-sm" onclick="window.setAppointmentFunc(${inspection.id}, 1)">Set Appointment for <br/>${formatDate(selectedDate)} AM</button>`;
        content = content + `<button class="tw-mt-4 btn-primary btn-sm" onclick="window.setAppointmentFunc(${inspection.id}, 2)">Set Appointment for <br/>${formatDate(selectedDate)} Midday</button>`;
        content = content + `<button class="tw-mt-4 btn-primary btn-sm" onclick="window.setAppointmentFunc(${inspection.id}, 3)">Set Appointment for <br/>${formatDate(selectedDate)} PM</button>`;
        content = content + `</div>`;
      } else {
        content = content + `<p class="tw-mt-2">Appointment: <strong>${formatDate(inspection.inspection_date)} ${inspection.inspection_time}</strong></p>`;
      }
      content = content + `</div>`;

      mapInfoWindow.setContent(content);
      mapInfoWindow.open({ anchor: marker, map });
    });

    return marker;

    // const color = getColorForInspection(inspection);
    // const pinConfig = {
    //   background: color,
    //   borderColor: color,
    //   glyphColor: "white"
    // };
    //
    // const pin = new PinElement(pinConfig);
    // return pin;

  }

  const updateMapBounds = async () => {
    if (!map) {
      return;
    }
    markers.forEach(marker => marker.setMap(null));
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const markerList = [];
    if (map && plannedInspections) {
      const bounds = new window.google.maps.LatLngBounds();
      for (const inspection of plannedInspections) {
        // const marker = new AdvancedMarkerElement({
        //   map,
        //   position: { lat: inspection.lat, lng: inspection.lng },
        //   content: createPin(PinElement, inspection).element
        // });
        const marker = createMarker(AdvancedMarkerElement, inspection);
        markerList.push(marker);
        bounds.extend(marker.position);
      }
      map.fitBounds(bounds);
      setMarkers(markerList);
    }
  };

  function mouseOverInspection(inspection) {
    // make map pin expand
    const pin = document.getElementById(`ins-${inspection.id}`);
    // pin.style.width = "2rem";
    // pin.style.height = "2rem";
    pin.style.fontSize = "1.4rem";
    pin.style.padding = "0.9rem";
    pin.style.border = "2px solid black";
    pin.style.boxShadow = "0 0 15px 10px rgba(0, 0, 0, 0.3)";
    pin.style.zIndex = "1000";
  }

  function mouseOutInspection(inspection) {
    // make map pin shrink
    const pin = document.getElementById(`ins-${inspection.id}`);
    if (pin) {
      pin.style.padding = "8px 8px";
      pin.style.fontSize = "0.8rem";
      pin.style.border = "none";
      pin.style.boxShadow = "none";
      pin.style.zIndex = "0";
    }
  }

  const moveSelectedDate = numberOfDays => {
    const date = new Date(selectedDate);
    date.setDate(date.getDate() + numberOfDays);
    setSelectedDate(date.toISOString().split("T")[0]);
  };

  return (
    <div>
      <h1>Planner</h1>

      <div className={"tw-flex tw-gap-8 tw-mb-4 tw-items-baseline"}>
        <div className={"tw-flex tw-gap-2 tw-items-baseline"}>
          <input className="tw-rounded-lg" type="date" value={selectedDate}
            onChange={e => setSelectedDate(e.target.value)}/>
          <i title="Back one day" className="tw-text-lg fa fa-chevron-left tw-cursor-pointer" onClick={() => moveSelectedDate(-1)}></i>
          <i title="Forward one day" className="tw-text-lg fa fa-chevron-right tw-cursor-pointer" onClick={() => moveSelectedDate(1)}></i>
        </div>
        <select className="tw-rounded-lg tw-w-96" onChange={e => setSelectedInspector(e.target.value)}>
          <option value="0">Select Inspector</option>
          {inspectors.map((inspector) =>
            <option key={inspector.id} value={inspector.id}>{inspector.name}</option>
          )}
        </select>
        <label className="tw-flex tw-items-center">
          <input type="checkbox" className="tw-form-checkbox" value={showInspectionsWithNoAppointment}
            onChange={(e) => setShowInspectionsWithNoAppointment(e.target.checked)}/>
          <span className="tw-ml-2">Include inspections with no appointment</span>
        </label>
      </div>

      {!(selectedDate && selectedInspector) ? <p>Please choose a date and inspector</p> : !plannedInspections ?
        <p>No planned inspections found</p> : null}

      <div ref={mainDiv}
        className={`${plannedInspections?.length > 0 ? "xl:tw-grid tw-grid-cols-3 xl:tw-gap-4" : "tw-hidden"} tw-my-4`}>
        <div className={"tw-overflow-y-scroll"}>
          <table className={"table planner-inspections-table tw-flex-grow-0 text-sm"}>
            <thead>
              <tr className={"tw-bg-pink-500 tw-bg-opacity-25 tw-rounded-t-lg"}>
                <th>Policy</th>
                <th>Insured</th>
                <th>Address</th>
                <th>Time</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {plannedInspections?.map((inspection) =>
                <tr key={inspection.id}
                  className={"odd:tw-bg-gray-200 hover:tw-bg-primary-light tw-transition tw-duration-200"}
                  onMouseOver={() => mouseOverInspection(inspection)}
                  onMouseOut={() => mouseOutInspection(inspection)}>
                  <td><a target={"_blank"} href={`/inspections/${inspection.policy_no}/process/pre_r`}
                    rel="noreferrer">{inspection.policy_no}</a></td>
                  <td>{inspection.insured}</td>
                  <td>{inspection.address}<br/>{inspection.city}<br/>{inspection.state}, {inspection.zip}
                  </td>
                  <td>{inspection.inspection_time}</td>
                  <td>
                    <p className={`tw-text-white tw-p-1 tw-w-8 tw-rounded tw-text-center`}
                      style={{ backgroundColor: getColorForInspection(inspection) }}>{inspection.type}</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div id={"map"}
          className={`tw-my-4 2xl:tw-my-0 tw-shadow tw-overflow-hidden tw-rounded-xl tw-w-full tw-col-span-2 tw-max-h-screen`}>
        </div>
      </div>

    </div>
  );

};

export default Planner;