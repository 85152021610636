import React from 'react';

export default function FSButton({ icon, endIcon, color, textColor, className, onClick, children }) {
  return (
    <button type="button" onClick={(e) => onClick && onClick(e)}
      className={`tw-my-2 tw-px-4 tw-py-2 tw-border tw-border-transparent tw-shadow-sm tw-text-sm tw-font-light md:tw-text-sm md:tw-font-medium 
                tw-rounded-md tw-text-${textColor || "white"} tw-bg-${color} focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-${color}-light 
                tw-transform tw-ease-in tw-duration-200 hover:tw-bg-${color}-dark ${className}`}>
      <div>
        {icon && <span className={"tw--ml-1 tw-mr-2 tw-h-5 tw-w-5"}>{icon}</span>}
        <span className="tw-uppercase">{children}</span>
        {endIcon && <span className={"tw-ml-1 tw--pt-1 tw-mr-2 tw-h-5 tw-w-5"}>{endIcon}</span>}
      </div>
      
    </button>
  );
}